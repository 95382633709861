import '../src/assets/css/styles.css';
import logo from './assets/deepgazeweb1.png';
import logo_footer from './assets/cognitiweb1.png';

function App() {
  return (
<div className="fondo">
      <div>
        <center>
          
        </center>
        <div className="container">
          <div className="item item-1"></div>
          
          <div className="item item-7">
          <a href='https://cognitive.la/'><img className='item-71' src={logo} width='500' height='100' alt='logo'/></a>
         
          </div>
          <div className="item item-8"></div>
          <div className='itemx item-11 text-white text-center'>DEEPGAZE  © es el portal de una Suite de soluciones de Inteligencia Artificial en la nube para los clientes de Cognitive Latam.</div>
          <div className="item item-8"></div>
          <div className="item item-9"></div>
          <div className="item item-5"></div>
          <div className="itemx item-11">DEEPGAZE © es un producto de</div>
          <div className="itemx item-12"><img src={logo_footer} width='178' height='48' alt='logo'/></div>
        </div>
      </div>
   

    </div>     
  );
}

export default App;
