// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fondo  {
	margin: 0px;
  	width: 100%;
	height: 700px;
	overflow-x: hidden;
	background: #4802ff;
	background: rgb(77, 38, 179);
	background: linear-gradient(90deg, rgba(77, 38, 179, 1) 40%, rgba(179, 76, 38, 1) 100%);
	background-size: cover;
  }
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;CACC,WAAW;GACT,WAAW;CACb,aAAa;CACb,kBAAkB;CAClB,mBAAmB;CACnB,4BAA4B;CAC5B,uFAAuF;CACvF,sBAAsB;EACrB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.fondo  {\n\tmargin: 0px;\n  \twidth: 100%;\n\theight: 700px;\n\toverflow-x: hidden;\n\tbackground: #4802ff;\n\tbackground: rgb(77, 38, 179);\n\tbackground: linear-gradient(90deg, rgba(77, 38, 179, 1) 40%, rgba(179, 76, 38, 1) 100%);\n\tbackground-size: cover;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
