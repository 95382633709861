import '../src/assets/css/styles.css';
import logo from './assets/deepgazeweb1.png';
import logo_footer from './assets/cognitiweb1.png';
import React, { useEffect } from 'react';
import '@n8n/chat/style.css';
import { createChat } from '@n8n/chat';

function App() {

  	useEffect(() => {
		createChat({
			webhookUrl: 'https://pvsbot.deepgaze.xyz/webhook/bb4c522f-bdfe-4876-b319-2ceb77c947e8/chat',
      initialMessages : [ 
        '¡Hola! 👋' , 
        'Soy tu asistente virtual y estoy aquí para ayudarte.' 
      ] , 
      i18n : { 
        en : { 
          // title : '¡Hola! 👋 Soy tu asistente virtual y estoy aquí para ayudarte.' ,
          title : '¡Hola! 👋' , 
          subtitle : 'Si tienes preguntas o necesitas información, ¡pregunta sin miedo! Estoy disponible 24/7 para brindarte la mejor experiencia posible.' ,
          // subtitle : "Inicia un chat. Estamos aquí para ayudarte las 24 horas, los 7 días de la semana." , 
          footer : '' , 
          getStarted : 'Nueva conversación' , 
          inputPlaceholder : '¿En qué te puedo ayudar? 😊 ',
          // inputPlaceholder : 'Escribe tu pregunta...' , 
        } , 
      } ,
      poweredBy: false,
		});
	}, []);



  return (
<div className="fondo">
      <div>
        <center>
          
        </center>
        <div className="container">
          <div className="item item-1"></div>
          
          <div className="item item-7">
          <a href='https://cognitive.la/'><img className='item-71' src={logo} width='500' height='100' alt='logo'/></a>
         
          </div>
          <div className="item item-8"></div>
          <div className='itemx item-11 text-white text-center'>DEEPGAZE  © es el portal de una Suite de soluciones de Inteligencia Artificial en la nube para los clientes de Cognitive Latam.</div>
          <div className="item item-8"></div>
          <div className="item item-9"></div>
          <div className="item item-5"></div>
          <div className="itemx item-11">DEEPGAZE © es un producto de</div>
          <div className="itemx item-12"><img src={logo_footer} width='178' height='48' alt='logo'/></div>
        </div>
      </div>
   

    </div>     
  );
}

export default App;


// // App.tsx
// import { useEffect } from 'react';
// import '@n8n/chat/style.css';
// import { createChat } from '@n8n/chat';

// export const App = () => {
// 	useEffect(() => {
// 		createChat({
// 			webhookUrl: 'YOUR_PRODUCTION_WEBHOOK_URL'
// 		});
// 	}, []);

// 	return (<div></div>);
// };